const TOKEN_ADDRESS = {
  56: "0x16963195bd97caba6ee12dc2350e662fac2e248a",
  4002 : "0xD974d9B3b48f6b4F9f473Ce3c175670b0A60A16a",
};

const STAKING_ADDRESS = {
  56: "0xfAeCA75F9704Cbc551e5b7363C1236B9b8F635D9",
  4002 : "0xefd33638d30e4d625f1b6535cf0781267f52f177",
};

export { TOKEN_ADDRESS, STAKING_ADDRESS };
