import React, { useContext } from "react";
import { useWeb3React } from "@web3-react/core";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { formatAddress } from "../../utils/helpers";

import Backdrop from "./Backdrop";
import "./Modal.scss";
import Button from "../Button";
import { modalVaraints } from "../../helpers/animation";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { IDepositData } from "../../store/types";
import { useUserStakingData } from "../../hooks";
import { TransactionContext } from "../../store/context/TransactionContext";

interface IDepositModal {
  modal: boolean;
  handleClose?: () => void;
  data: IDepositData;
  handleGetContractDetails: () => Promise<void>;
}

const DepositModal: React.FC<IDepositModal> = ({
  modal,
  handleClose,
  data,
  handleGetContractDetails,
}) => {
  const { account, library, chainId } = useWeb3React();
  const { userData, refetch } = useUserStakingData();
  const { setTransaction } = useContext(TransactionContext);
  const [searchParams] = useSearchParams();
  const referralAddress = searchParams.get("ref");
  
  const handleDeposit = async () => {
    if (!account || !chainId) return;
    if (!userData.tokenBalance)
      return setTransaction({
        loading: true,
        status: "error",
        message:
          "Insufficient TRT token to deposit. Buy some USDT token to deposit.",
      });
    try {
      const { depositAmount } = data;
      setTransaction({ loading: true, status: "pending" });
      const { setStake } = await import("../../utils/contractMethods");
      await setStake(
        account,
        library?.provider,
        chainId,
        depositAmount,
        referralAddress
      );
      await refetch();
      handleGetContractDetails();
      setTransaction({ loading: false, status: "success" });
    } catch (error: any) {
      console.log(error?.message);
      if (error?.code === 4001) {
        return setTransaction({
          loading: false,
          status: "error",
          message: error.message,
        });
      }
      if (error?.code === -32603) {
        return setTransaction({
          loading: false,
          status: "error",
          message: error.data.message.split(":")[1],
        });
      }
      setTransaction({ loading: false, status: "error" });
    }
  };

  return (
    <Backdrop handleClose={handleClose} isOpen={modal}>
      <AnimatePresence exitBeforeEnter>
        {modal && (
          <motion.div
            className="deposit_modal"
            onClick={(e) => e.stopPropagation()}
            variants={modalVaraints}
            animate="animate"
            initial="initial"
            exit="exit"
          >
            <div className="deposit_modal-content">
              <div className="modal-header">
                <h3>Deposit Review</h3>
                <div
                  className="close"
                  onClick={() => handleClose && handleClose()}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="content">
                <div data-position="flex-between">
                  <p>Deposit Amount</p>
                  <b>{data.depositAmount} TRT</b>
                </div>
                {referralAddress && (
                  <div data-position="flex-between">
                    <p>Referrer Address</p>
                    <b>{formatAddress(referralAddress)}</b>
                  </div>
                )}
                <Button
                  onClick={() => {
                    if (handleClose) handleClose();
                    handleDeposit();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Backdrop>
  );
};

export default DepositModal;
